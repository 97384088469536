<template>
    <div>
        <v-container>
            <div v-if="isQuoteLoaded">
                <noscript v-if="submittingPayment">
                    <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petqu0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
                </noscript>
                <v-row class="d-flex flex-wrap">
                    <v-col class="family-header" cols="12" v-if="isFamilyPlan">
                        <v-card-title id="familyPlanConfirmation">
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" class="d-flex flex-row px-0">
                                        <PetView v-for="pet in petInfo" :key="pet.id" :aria-label="pet.name + 'Policy.'" :isChallenger="true" :isSimpleLayout="false" :petDetails="pet" :isCurrent="false" :showDetails="true" :isEditable="false"></PetView>
                                    </v-col>  
                                    <!-- <v-col aria-live="polite" cols="auto" class="d-flex flex-column py-1 ml-auto">
                                        <v-icon x-large :class="agreedPolicies.includes(petInfo[0].id) ? 'secondary--text  text--darken-2' : 'ml_darkergray--text' ">
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <span v-if="agreedPolicies.includes(petInfo[0].id)" class="caption secondary--text  text--darken-2">
                                            <span class="d-sr-only">As you have agreed the terms for pets your policy is </span>APPROVED
                                        </span>
                                        <span v-else class="caption ml_darkergray--text">
                                            <span class="d-sr-only">As you have not agreed the terms for pets your policy is </span>PENDING CHO</span>
                                    </v-col> -->
                                </v-row>
                            </v-card-title>
                            <v-divider class="thick-divider" role="none"></v-divider>
                    </v-col>
                    <v-col class="form-col-section" cols="12" md="7" v-if="!isFamilyPlan">
                        <v-card flat v-if="selectedPlans.length > 1" class="mt-10">
                            <v-card-title>
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" class="text-center mb-4"> 
                                        <h2 class="primary--text primary--text text-uppercase confirmation-title-h2 ">Your Plan Summary</h2>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider  role="none"></v-divider>
                        </v-card>
                        <v-card class="v-card-agreement mt-10"  flat v-for="(plan,index) in selectedPlans" :key="plan.planRate" role="region" :aria-label="'Policy for'+petInfo[index].name">
                            <v-card-title :id="'planConfirmation-'+index" class="mb-6">
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" class="text-center"> 
                                        <h2 class="primary--text primary--text text-uppercase confirmation-title-h2 font-weight-medium "> <span v-if="selectedPlans.length === 1" v-html="renderSummarySectionTitle"></span> <span v-else>{{ petInfo[index].name}}</span></h2>
                                    </v-col>
                                    <v-col cols="12" class="flex-grow-1 d-flex align-center justify-center py-0">
                                        <PetView :aria-label="petInfo[index].name + 'Policy.'" :petDetails="petInfo[index]" :isCurrent="false" :isSimpleLayout="true" :isChallenger="false" :showDetails="true" :isEditable="false"></PetView>
                                    </v-col>
                                    <!-- <v-col aria-live="polite" cols="auto" class="d-flex flex-column py-1">
                                        <v-icon large :class=" agreedPolicies.includes(petInfo[index].id) ? 'secondary--text  text--darken-2' : 'ml_darkergray--text' ">
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <span v-if=" agreedPolicies.includes(petInfo[index].id)" class="caption secondary--text  text--darken-2">
                                            <span class="d-sr-only">As you have agreed the terms for "{{petInfo[index].name}}" your policy is </span>APPROVED
                                        </span>
                                        <span v-else class="caption ml_darkergray--text"> 
                                            <span class="d-sr-only">As you have not agreed the terms for "{{petInfo[index].name}}" your policy is </span>PENDING CHo
                                        </span>
                                    </v-col> -->
                                </v-row>
                            </v-card-title>
                            <v-card-text  role="region" aria-label="Policy Details">
                                <v-row no-gutters class="order-details-grid">
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Your Rate:</v-col>
                                            <v-col cols="7"> {{plan.planRate}} </v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters class="d-flex justify-space-between">
                                            <v-col cols="5"  class="font-weight-bold">Annual Limit:</v-col>
                                            <v-col cols="7"> {{ reformatPrice ( plan.annualLimit, true) }}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters  class="d-flex justify-space-between">
                                            <v-col cols="5"  class="font-weight-bold">Reimbursement:</v-col>
                                            <v-col cols="7"> {{ plan.reimbursement}}</v-col>
                                        </v-row>
                                        <v-divider  role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters  class="d-flex justify-space-between">
                                            <v-col cols="5"  class="font-weight-bold">Deductible:</v-col>
                                            <v-col cols="7"> {{ reformatPrice (plan.deductible, true) }}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12" role="none" v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                                        <v-row no-gutters  class="d-flex justify-space-between" role="row">
                                            <v-col cols="5"  class="font-weight-bold" role="rowheader">{{routineCareLabel}}:</v-col>
                                            <v-col cols="7" v-if="plan.routineCare === 'None'" role="cell">No</v-col>
                                            <v-col cols="7" v-else-if="plan.routineCare === 'Standard Wellness'" role="cell">Yes</v-col>
                                            <v-col cols="7" v-else role="cell"> {{ reformatCurrencyString (plan.routineCare, true) }}</v-col>
                                        </v-row>
                                        <v-divider  role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters class="d-flex justify-space-between">
                                            <v-col cols="5"  class="font-weight-bold">Accident Coverage Begins:</v-col>
                                            <v-col cols="7"> {{plan.accidentalCoverageStart}}</v-col>
                                        </v-row>
                                        <v-divider  role="none"></v-divider>

                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row no-gutters class="d-flex justify-space-between">
                                            <v-col cols="5"  class="font-weight-bold">Illness Coverage Begins:</v-col>
                                            <v-col cols="7"> {{plan.illnessCoverageStart}}</v-col>
                                        </v-row>
                                        <v-divider  role="none"></v-divider>

                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text v-if="payOption === 'creditCard'">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="d-flex flex-column">
                                            <b :id="question-index">
                                                Would you like to pay annually and
                                                <span class="primary--text">
                                                    save on installment fees?
                                                </span>
                                            </b>
                                            <span v-if="paymentFrequency === 'Monthly'" class="caption">I understand that if I elect to pay premiums monthly, there is an installment fee of $3.00 per month.</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-radio-group row v-model="paymentFrequency" class="mt-0" @change="setConfirmPolicyPaymentFrequency($event,index)">                                            
                                            <v-radio :aria-label="'for '+petInfo[index].name+' Would You Like To have Annual Plan? No'" :id="'MonthlyRadio'+index" tabindex="0" label="Pay Monthly" value="Monthly"></v-radio>
                                            <v-radio :aria-label="'for '+petInfo[index].name+' Would You Like To have Annual Plan ? Yes'" :id="'AnnuallyRadio'+index" tabindex="0" label="Pay Annually" value="Annually"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions class="checkbox-agreement d-flex align-start">
                                <div class="fake_checkbox_wrapper">
                                    <input type="checkbox" class="transparent_checkbox" :id="'agreedPolicies-chk-native-' + index" v-model="agreedPolicies" :aria-label="'Approve ' + petInfo[index].name + ' Policy by Checking this checkbox, you acknowledge you have read and agree to MetLife policies shown ahead.'" :checked="agreedPolicies.includes(petInfo[index].id)?'checked':null" @change="handleInputClick(index)"/>
                                    <v-checkbox v-model="agreedPolicies"
                                                :value="petInfo[index].id"
                                                ref="'checkbox'+index"
                                                :id="'agreedPolicies-chk-' + index"
                                                aria-required="true"
                                                @click="handleChecked(index)"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :aria-labelledby="'chk-box'+index"
                                                :aria-label="'Approve ' + petInfo[index].name + ' Policy by Checking the Agreement Checkbox, you acknowledge you have read and agree to MetLife policies shown ahead.'"
                                                >
                                    </v-checkbox>
                                </div>
                                <div class="body-2" :id="'chk-box'+index">
                                    By checking this box, I have electronically signed and acknowledge that I have read and agree to MetLife’s
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" rel='noopener noreferrer'>Application Agreement</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" rel='noopener noreferrer'>Fraud Warning</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" rel='noopener noreferrer'>Privacy Policy</a>
                                    <a v-if="owner.state === 'NJ'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')">, Mass Marketing Discount Disclosure</a>
                                    <a v-if="owner.state === 'ME'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')">, Disclosure for Maine Residents</a>
                                    and
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/images/E-consent.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/images/E-consent.pdf')" rel='noopener noreferrer'>Electronic Delivery and Consent Notice</a>{{ owner.state !== 'NY' ? "." : " including the following New York fraud warning:" }}
                                    <v-card flat max-height="47" v-if="owner.state === 'NY'" class="scrollable mt-2" >
                                        <p>Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation. </p>
                                    </v-card>
                                    
                                    <!-- You agree to be billed automatically for your policy on an annual or monthly basis via the payment schedule and method selected. -->
                                </div>
                            </v-card-actions>
                            <v-card-text class="px-0">
                                <div class="v-messages theme--light" v-if="partnerId == 93851">
                                    <div class="v-messages__wrapper">
                                        <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                            <span>
                                                <p class="body-2 mb-1">Your quote for Pet insurance will reflect monthly premium. Please note that if payroll deduction is selected as your preferred payment method, that deductions will be taken bi-weekly.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="v-messages theme--light" v-if="partnerId == 96778">
                                    <div class="v-messages__wrapper">
                                        <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                            <span>
                                                <p class="body-2 mb-1">Your quote for Pet Insurance reflects your monthly premium. Please note, if you choose payroll deduction as your preferred payment method, deductions will be withheld biweekly from your State of Michigan pay check.  The biweekly premium is calculated by multiplying the monthly amount by 12 months, then dividing that by 26 pay periods.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="form-col-section" cols="12" md="7" v-else>
                        <v-card class="v-card-agreement mt-10" flat>
                            <v-card-text tabindex="0" role="region" aria-labelledby="familyPlanConfirmation">
                                <v-row>
                                    <v-col cols="12" class="d-flex flex-column justify-center align-center">
                                        <h2 class="primary--text primary--text text-uppercase confirmation-title-h2 ">Your Family Plan</h2>
                                        <div class="pet-avatar max-width-none">
                                            <ul class="pet-details horizontal-list body-1 flex-row ml_darkergray--text">
                                                <li v-for="pet in petInfo" key="pet.id"> {{pet.name}}</li>
                                            </ul>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="order-details-grid">
                                    <v-col class="py-2" cols="12">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Your Rate:</v-col>
                                            <v-col cols="7">{{familyPlan[0].planRate}} </v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Annual Limit:</v-col>
                                            <v-col cols="7"> {{ reformatPrice (familyPlan[0].annualLimit , true) }}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row  class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Reimbursement:</v-col>
                                            <v-col cols="7">{{familyPlan[0].reimbursement}}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Deductible:</v-col>
                                            <v-col cols="7"> {{ reformatPrice (familyPlan[0].deductible, true) }}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12" v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">{{routineCareLabel}}:</v-col>
                                            <v-col cols="7" v-if="familyPlan[0].routineCare === 'None'">No</v-col>
                                            <v-col cols="7" v-else-if="familyPlan[0].routineCare === 'Standard Wellness'"> Yes </v-col>
                                            <v-col cols="7" v-else> {{ reformatCurrencyString (familyPlan[0].routineCare , true) }}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Accident Coverage Begins:</v-col>
                                            <v-col cols="7"> {{familyPlan[0].accidentalCoverageStart}}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                    <v-col class="py-2" cols="12">
                                        <v-row class="d-flex justify-space-between">
                                            <v-col cols="5" class="font-weight-bold">Illness Coverage Begins:</v-col>
                                            <v-col cols="7"> {{familyPlan[0].illnessCoverageStart}}</v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text v-if="payOption === 'creditCard'">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="d-flex flex-column">
                                            <b>Would you like to pay annually and
                                                <span class="primary--text">
                                                    save on installment fees?
                                                </span>
                                            </b>
                                            <span v-if="paymentFrequency === 'Monthly'" class="caption">I understand that if I elect to pay premiums monthly, there is an installment fee of $3.00 per month.</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-radio-group v-model="paymentFrequency" row class="mt-0" @change="setConfirmPolicyPaymentFrequency">
                                            <v-radio aria-label="Would You Like To have Annual Plan for your Pets? No" id="MonthlyRadio" label="Pay Monthly" tabindex="0" value="Monthly"></v-radio>
                                            <v-radio aria-label="Would You Like To have Annual Plan for your Pets? Yes" id="AnnuallyRadio" label="Pay Annually" tabindex="0" value="Annually"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions class="checkbox-agreement d-flex align-start">
                                <div class="fake_checkbox_wrapper">
                                    <input type="checkbox" class="transparent_checkbox" :id="'agreedPolicies-chk-native-'+index"  aria-label="Approve Family Policy by Checking this checkbox, you acknowledge you have read and agree to MetLife policies shown ahead." :checked="agreedPolicies.includes(petInfo[index].id)?'checked':null" @change="handleInputClick(0)"/>
                                    <v-checkbox v-model="agreedPolicies"
                                                :value="petInfo[0].id"
                                                id="agreedPolicies-chk-0"
                                                aria-required="true"                                                
                                                @click="handleChecked(0)"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :aria-labelledby="'chk-box'+index"
                                                aria-label="Approve Family Policy by Checking the Agreement Checkbox, you acknowledge you have read and agree to MetLife policies shown ahead."
                                                >
                                    </v-checkbox>
                                </div>
                                <div class="body-2" :id="'chk-box'+index">
                                    By checking this box, I have electronically signed and acknowledge that I have read and agree to MetLife’s
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" rel='noopener noreferrer'>Application Agreement</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" rel='noopener noreferrer'>Fraud Warning</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" rel='noopener noreferrer'>Privacy Policy</a>
                                    <a v-if="owner.state === 'NJ'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')">, Mass Marketing Discount Disclosure</a>
                                    <a v-if="owner.state === 'ME'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')">, Disclosure for Maine Residents</a>
                                    and
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/images/E-consent.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/images/E-consent.pdf')" rel='noopener noreferrer'>Electronic Delivery and Consent Notice</a>{{ owner.state !== 'NY' ? "." : " including the following New York fraud warning:" }}
                                    <v-card flat max-height="47" v-if="owner.state === 'NY'" class="scrollable mt-2" >
                                        <p>Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation. </p>
                                    </v-card>
                                    <!-- You agree to be billed automatically for your policy on an annual or monthly basis via the payment schedule and method selected. -->
                                </div>
                            </v-card-actions>
                            <v-card-text class="px-0">
                                <div class="v-messages theme--light" v-if="partnerId == 93851">
                                    <div class="v-messages__wrapper">
                                        <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                            <span>
                                                <p class="body-2 mb-1">Your quote for Pet insurance will reflect monthly premium. Please note that if payroll deduction is selected as your preferred payment method, that deductions will be taken bi-weekly.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="v-messages theme--light" v-if="partnerId == 96778">
                                    <div class="v-messages__wrapper">
                                        <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                            <span>
                                                <p class="body-2 mb-1">Your quote for Pet Insurance reflects your monthly premium. Please note, if you choose payroll deduction as your preferred payment method, deductions will be withheld biweekly from your State of Michigan pay check.  The biweekly premium is calculated by multiplying the monthly amount by 12 months, then dividing that by 26 pay periods.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="side-col-section totals-column" cols="12" md="5">

                        <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                            <v-container>
                                <v-row>
                                    <v-col cols="10" offset="1" class="text-center">
                                        <v-card-title>
                                            <v-img 
                                                alt="Snoopy"
                                                class="shrink side-card-img"
                                                contain
                                                :src="step4SideImgSource"
                                                transition="scale-transition"
                                                max-height="212"
                                                width="auto"
                                                v-if="step4SideImgSource !== ''"
                                            />
                                        </v-card-title>
                                        <v-card-text aria-live="polite" aria-atomic="true" role="region" aria-labelledby="breakdownRegionLabel">
                                            <div class="quote-breakdown d-flex flex-column">
                                                <v-row no-gutters class="quote-section">
                                                    <v-col cols="7" class="quote-text text-left"> Total Premium </v-col>
                                                    <v-col cols="5" class="quote-value">
                                                        <span class="d-sr-only">Total Premium, </span> {{ reformatPrice(quoteTotals[0].subTotal) }}
                                                    </v-col>
                                                </v-row>
                                                <v-divider role="none"></v-divider>
                                                <div v-if="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                                                    <div v-for="(fee, index) in sortedFees(this.policies[0].plan.paymentSchedule.feeTotalBreakdown)" :key="index">
                                                        <v-row no-gutters class="quote-section" v-if="fee.amount !== 0">
                                                            <v-col cols="7" class="quote-text text-left" aria-label="Fees,"> {{ getFeesShortDescription(fee.description) }} </v-col>
                                                            <v-col cols="5" class="quote-value">
                                                                <span class="d-sr-only">Fees, </span> + {{ 
                                                                    !isFamilyPlan && petsCount > 1 
                                                                        ? owner.state === 'GA' 
                                                                            ? reformatPrice((fee.amount / 11) * petsCount) 
                                                                            : reformatPrice((fee.amount / 12) * petsCount)
                                                                        : owner.state === 'GA' 
                                                                            ? reformatPrice(fee.amount / 11) 
                                                                            : reformatPrice(fee.amount / 12)
                                                                }}
                                                            </v-col>
                                                        </v-row>
                                                        <v-divider role="none"></v-divider>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <v-row no-gutters class="quote-section" v-if="quoteTotals[0].fees !== 0">
                                                        <v-col cols="7" class="quote-text text-left" aria-label="Fees,"> {{ getFeesLabel() }} </v-col>
                                                        <v-col cols="5" class="quote-value">
                                                            <span class="d-sr-only">Fees, </span> + {{ reformatPrice(quoteTotals[0].fees) }}
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <div v-show="quoteTotals[0].taxes != 0">
                                                    <v-divider role="none"></v-divider>
                                                    <v-row no-gutters class="quote-section">
                                                        <v-col cols="7" class="quote-tex text-left" aria-label="Taxes,"> Taxes </v-col>
                                                        <v-col cols="5" class="quote-value">
                                                            <span class="d-sr-only">Taxes, </span> + {{reformatPrice(quoteTotals[0].taxes)}}
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <v-divider role="none"></v-divider>
                                                <v-row no-gutters class="quote-section" v-if="quoteTotals[0].discounts !== 0">
                                                    <v-col cols="7" class="quote-text text-left" aria-label="Discounts,"> Discounts </v-col>
                                                    <v-col cols="5" class="quote-value">
                                                        <span class="d-sr-only">Discounts, </span> - {{reformatPrice(quoteTotals[0].discounts)}}
                                                    </v-col>
                                                </v-row>
                                                <v-divider role="none"></v-divider>
                                                
                                                <v-row no-gutters class="quote-total mt-3">
                                                    <v-col cols="12" class="quote-text text-left font-weight-bold"> Your Total </v-col>
                                                    <v-col cols="12" class="quote-value mt-4">
                                                        <div class="price-value xxl">
                                                            <span class="currency font-weight-bold"> {{  getPriceSplit( quoteTotals[0].total )[0] }} </span>
                                                            <span class="units font-weight-bold">    {{  getPriceSplit( quoteTotals[0].total )[1] }} </span>
                                                            <span class="cents font-weight-bold d-flex flex-column align-start">    
                                                                <span>{{  getPriceSplit( quoteTotals[0].total )[2] }} </span>
                                                                <span class="payment-frequency d-flex flex-column align-start pl-2">{{' ' + paymentFrequency}} <br/> Premium</span>
                                                            </span>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <!-- <span class="ml_blue--text d-flex flex-row justify-center align-center"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}</span> -->
                                            <div class=" text-left d-flex flex-row justify-start align-start" v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                                    <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> <span class="ml_gray--text text--darken-4">{{ discount.name }}</span>
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="d-flex flex-column black--text">
                                            <v-btn block x-large color="ml_purple" class="nextBtnFocus" @click="goNext()">
                                                Complete Purchase
                                                <v-icon small>mdi-play</v-icon>
                                            </v-btn>
                                            <p class="pt-3 pb-7 support-phone">Or call: <a :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a></p>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>   

                    </v-col>
                </v-row>
            </div>

        </v-container>
    </div>
</template>
<script>
    import PetView from '../challenger/shared/pet.vue';

    import paymentConfirmation from '../global/js/paymentConfirmation';

    export default {
        mixins: [paymentConfirmation],
        name: "QFPaymentConfirmation",
        components: {
            PetView
        },
        computed: {
            availableDiscounts: function () {
                return this.$store.state.discounts.options;
            },
            savedDiscounts: function () {
                return this.$store.state.discounts.selection;
            },
            partnerId: function () {
                return this.$store.getters.partnerId;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            renderSummarySectionTitle() {
                return this.dynamicContent && this.dynamicContent.summarySectionTitle 
                    ? this.interpolateContent(this, this.dynamicContent.summarySectionTitle) 
                    : this.interpolateContent(this, this.defaultDynamicContent.summarySectionTitle);
            }
        }, 
        created() {
            this.setImageSource(this.defaultSidebarImage, this.dynamicSidebarImage, "4", "setStep4SideImgSource");
        },
        mounted(){
            this.delay(2000).then(() => this.runInvoca());
        },
        methods: {
            goNext() {
                this.$emit("goNext");
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                        leadId: this.leadId,
                        stepName: 'Purchase Policy'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            getSavedDiscountsData(){
                return this.availableDiscounts.filter( discount => {
                    return this.savedDiscounts.includes(discount.discountId);
                })
            },
        }
    }
</script>
<style>
    .max-width-none {
        max-width: none !important;
    }
</style>