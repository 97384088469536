<template>
    <div class="section-wrap">
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="handleLoaderComplete" />
        <StepHeader :stepDetails=stepHeaderConfig :stepHeading="summaryHeaderTitle" :contactInfo="this.getPartnerContactInfo()" :imageName="step4SideImgSource" ref="stepHeader"></StepHeader>

        <div class="step-body">
            <div class="form-inner"> 
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card flat class="spacing-playground v-card-form pa-0">
                                <v-card-text class="pa-0">
                                    <v-row justify="center">
                                        <!-- v-if="paymentRequestList == null" -->
                                        <v-col cols="12" class="pa-0" v-if="!showSummary">
                                            <PaymentConfirmation 
                                                ref="paymentConfirmation"
                                                @setLoader="setLoader"
                                                @goNext="goNext"
                                            >
                                            </PaymentConfirmation>
                                        </v-col>
                                        <v-col cols="12" class="pa-0" v-else>
                                            <OrderSummary
                                                @completeAccountSetUp="completeAccountSetUp"
                                            ></OrderSummary>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>

        <v-footer ref="controlZoomLevel" app color="white" elevation="4">
            <v-row class="d-flex justify-center align-center" v-if="!showSummary">
                <v-col md="auto" cols="12" class="d-flex justify-center align-center">
                        <span class="body-1" v-if="this.pets.length === 1" v-html="renderSummaryFooterInstructions"></span>
                        <span class="body-1" v-else-if="isFamilyPlan">Purchase your family plan</span>
                        <span class="body-1" v-else>Purchase your plans</span>
                </v-col>
                <v-col cols="auto">
                    <v-btn @click="goBack">Back</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="ml_green" tabindex="0" @click="goNext">
                        Complete Purchase <v-icon class="mt-n1">mdi-menu-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center" v-else>
                <v-col cols="auto">
                    <v-btn color="ml_green" @click="completeAccountSetUp" v-if="!employeeToken">
                        {{nextButtonText}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>
<script>
    import PaymentConfirmation from "../../components/challenger/PaymentConfirmation.vue";
    import OrderSummary from '../../components/challenger/OrderSummary.vue';
    import StepHeader from '../../components/challenger/shared/StepHeader.vue';
    import DotLoader from '../../components/shared/DotLoader.vue';

    import order from '../Global/JS/order.js';

    export default {
        mixins: [order],
        components: { OrderSummary, PaymentConfirmation, StepHeader, DotLoader },
        data() {
            return {
                showSummary: false,
                previousButtonText: "Back",

                stepHeaderConfig: [],

                loading: false, 
                loaderText: "",
                onLoderComplete: () => {}
            }
        },
        created() {
            this.stepHeaderConfig = {
                stepHeading: this.getstepHeading(),
                currentStep: 4,
                totalSteps: 4
            };
        },
        mounted() {
            this.focusHeader();
        },
        computed: {
            paymentRequestList() {
                return this.$store.getters.getPaymentRequestList;
            },
            isFamilyPlan(){
                return this.$store.getters.isFamilyPlan;
            },
            pets(){
                return this.$store.getters.pets;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            }, 
            renderSummaryFooterInstructions() {
                return this.dynamicContent && this.dynamicContent.summaryFooterInstructions
                    ? this.interpolateContent(this, this.dynamicContent.summaryFooterInstructions) 
                    : this.interpolateContent(this, this.defaultDynamicContent.summaryFooterInstructions);
            },
            summaryHeaderTitle() {
                return this.defaultDynamicContent && this.defaultDynamicContent.summaryHeaderTitle
                    ? this.dynamicContent && this.dynamicContent.summaryHeaderTitle 
                        ? this.dynamicContent.summaryHeaderTitle 
                        : this.defaultDynamicContent.summaryHeaderTitle
                    : '';
            },
            step4SideImgSource(){
                return this.$store.getters.getStep4SideImgSource;
            },
        },
        methods: {
            focusHeader() {
                this.$nextTick(() => this.$refs.stepHeader.focus());
            },
            goBack() {
                this.$store.commit("setCurrentPage","Contact Info");
                this.$store.commit("clearPolicies");
                this.redirectToView("billing");
            },
            async goNext() {
                await this.setLoader(true, "Submitting payment, Please wait");

                if(await this.$refs.paymentConfirmation.submitPayment()){
                    this.$store.commit("setCurrentPage", "Order Confirmation");

                    this.$store.commit('setStepComplete', 'payment');
                    this.$store.commit('setStepActive', 'summary');

                    this.showSummary = true;
                }
                
            },
            redirectToView(step) {
                this.routerPush(step);
            },
            handleLoaderComplete() {
                if(this.onLoderComplete) {
                    this.onLoderComplete();
                    this.onLoderComplete = () => {};
                }
            },
            async setLoader(loading, loaderText, loaderCompleteCallback) {
                this.loading = loading;
                this.loaderText = loaderText;
                
                if(loaderCompleteCallback) {
                    this.onLoderComplete = null;
                    this.onLoderComplete = () => { 
                        loaderCompleteCallback();
                    }
                }
            },
            getstepHeading(){
                let stepHeading = "Please review and purchase to activate your plan.";
                if(this.pets.length > 1 && !this.isFamilyPlan){
                    stepHeading = "Please review and purchase to activate your plans.";
                }

                return stepHeading;
            },
        }
    }

</script>